import React, { useState, useEffect } from 'react';
import './tasks.css';
import { confirmTask } from '../api/api';
import { useNotification } from './NotificationProvider'

const Tasks = ({ tasks, onClose }) => {
  const { addNotification } = useNotification();
  const [isOpen, setIsOpen] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [buttonsEnabled, setButtonsEnabled] = useState(false); // Додаємо стан для контролю доступності кнопок

  useEffect(() => {
    // Встановлюємо таймер для активації кнопок через 0.5 секунди
    const timer = setTimeout(() => {
      setButtonsEnabled(true);
    }, 500);

    // Очищення таймера при розмонтажуванні компонента
    return () => clearTimeout(timer);
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    if (onClose) onClose();
  };

  const handleButtonClick = (task) => {
    setSelectedTask(task);
    setIsOpen(false);
    setShowConfirmation(true);
  };

  const handleConfirmation = async () => {
    // Ваш код для підтвердження завдання
    await confirmTask(localStorage.getItem('userId'), selectedTask.signature).catch((err) => addNotification('error', 'Что-то не так с сервером. Мы уже чиним. Админ на связи.'))
    setShowConfirmation(false);
    onClose()
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h1 className="tasks-title">Выполните задания</h1>
            <div className="tasks-buttons">
              {tasks.map((task, index) => (
                <a
                  key={index}
                  className="task-button"
                  href={`${task.link}`} // Замените на ссылку вашего канала
                  target="_blank" // Открытие в новой вкладке
                  rel="noopener noreferrer" // Рекомендуется для безопасности
                  onClick={() => handleButtonClick(task)} // Если хотите дополнительно выполнить логику
                  style={{ pointerEvents: buttonsEnabled ? 'auto' : 'none', opacity: buttonsEnabled ? 1 : 0.5 }} // Динамічна зміна властивостей
                >
                  {task.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="modal-overlay">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h1 className="tasks-title">Подтверждение выполнения задания</h1>
            <p>Вы выполнили задание "{selectedTask.name}". Подтверждаете?</p>
            <button onClick={handleConfirmation} className="task-button">
              Подтвердить
            </button>
            <button onClick={() => {
              setShowConfirmation(false);
              return onClose();
            }} className="task-button">
              Отменить
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Tasks;
