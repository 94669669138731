import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TapGame from "./components/BreadTapApp";
import Leaderboard from "./components/LeaderBord";
import Withdraw from "./components/Withdraw";
import NotificationProvider from "./components/NotificationProvider";

const App = () => {
  return (
    <NotificationProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<TapGame />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/withdraw" element={<Withdraw />} />
          </Routes>
        </div>
      </Router>
    </NotificationProvider>
  );
};

export default App;
