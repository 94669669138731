import React, { useState } from "react";
import { Link } from "react-router-dom";
import './withdraw.css';
import { withdraw, checker } from '../api/api';
import FooterNav from './FooterNav';
import Topbar from "./TopBar";
import { useNotification } from "./NotificationProvider";

const Withdraw = () => {
  const { addNotification } = useNotification();

  const [balance, setBalance] = useState(1200); // Стартовий баланс
  const [withdrawLink, setWithdrawLink] = useState("");
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const withdrawalButtons = [
    { amount: 200, label: '200 ⭐️' },
    { amount: 400, label: '400 ⭐️' },
    { amount: 600, label: '600 ⭐️' },
  ];
  const handleWithdrawLinkChange = (e) => {
    setWithdrawLink(e.target.value);
  };

  const handleAmountClick = async (amount) => {
    try {

      const res = await checker(localStorage.getItem('userId'), amount);
    } catch (error) {
      addNotification('error', error.response.data.message)
      return;
    }

    setSelectedAmount(amount);
    isButtonEnabled(true)
  };

  const submitWithdraw = async () => {
    if (!withdrawLink.trim()) {
      addNotification('info', "Пожалуйста, укажите ссылку");
      return;
    }

    try {
      const res = await withdraw(localStorage.getItem('userId'), withdrawLink, selectedAmount)

      addNotification('success')

      setNotificationVisible(true);
      setTimeout(() => {
        setNotificationVisible(false);
      }, 2000);

      // Очищення помилки
      setErrorMessage("");

    } catch (error) {
      if (error.response && error.response.data) {
        addNotification(error.response.data.message)

        // setErrorMessage(error.response.data.message);
        // setIsButtonEnabled(false);
        return;
      }
    }

    setIsButtonEnabled(true);
  };

  function getArray() {
    const count = +localStorage.getItem('totalCounter') || 0


    const array = []
    let amount = 200

    for (let i = 1; i <= 3; i++) {
      array.push({ amount: (amount * i) + count, label: `${(amount * i) + count} ⭐️` },
      )

    }

    return array
  }

  return (
    <div className="container">
      <Topbar />

      <div className="content">

        {/* <div className="header-v">
          <Link to="/" className="header-link">
            <h1 className="cursorPoint">Вывод средств</h1>
          </Link>
        </div> */}
        {/* <div className="balance">
          <p>Ваш баланс:</p>
          <p className="balance-amount">{localStorage.getItem('tapCounter')}</p>
        </div>
        <div className="withdraw-link">
          <p>Ссылка для вывода (Roblox):</p>
          <input
            type="text"
            placeholder="Укажите ссылку"
            value={withdrawLink}
            onChange={handleWithdrawLinkChange}
          />
        </div> */}
        <p className="text-p">Ваш баланс: <strong>{(+localStorage.getItem('tapCounter')).toFixed(3) || 0} ⭐️</strong></p>
        <p className="text-p">Сколько звезд вы хотите вывести?</p>

        <div className="withdrawal-buttons">
          {getArray()
            .map(button => (
              <button
                key={button.amount}
                className="withdrawal-button"
                onClick={() => handleAmountClick(button.amount)}
              >
                {button.label}
              </button>
            ))}
        </div>
        {isButtonEnabled && (
          <div class="withdrawal-form" id="withdrawal-form">
            <p>Вы выбрали вывод: <span></span>{selectedAmount} ⭐️</p>
            <input
              type="text"
              class="form-input"
              placeholder="Укажите ссылку"
              value={withdrawLink}
              onChange={handleWithdrawLinkChange}
            />
            <button class="submit-button" onClick={submitWithdraw}>Заказать</button>
            <p>Укажите ссылку на аккаунт, на который будут переведены звезды:
              ссылка вида t.me либо @</p>
          </div>
        )}

        {notificationVisible && (
          <p
            id="submit-notification"
            className={`submit-notification ${notificationVisible ? "visible" : ""}`}
          >
            Запрос успешно отправлен!
          </p>
        )}


        {errorMessage && (
          <p className={`error-notification ${errorMessage ? "visible" : ""}`}>
            {errorMessage}
          </p>
        )}
      </div>
      <FooterNav />
    </div >
  );
};

export default Withdraw;
